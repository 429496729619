import React from "react";
import ImageTransform from "../ggfx-client/module/components/image-transform";
import { AltSiteName } from "./utils";
const GetGGFXImage =(props)=> {
    return (
        <ImageTransform
                    imagesources={props.imagesource && props.imagesource.url}
                    renderer="srcSet"
                    imagename={props.imagename}
                    attr={{
                      alt: props.imagesource && props.imagesource.alternativeText
                        ? props.imagesource.alternativeText+AltSiteName
                        : props.fallbackalt+AltSiteName
                    }}
                    imagetransformresult={props.imagetransformresult}
                    id={props.id}
                  />
    )   
}
export default GetGGFXImage;
